<template>
<div>
    <v-dialog v-model="openModal" persistent max-width="800">
        <v-card>
            <v-card-title class="headline">¿Esta seguro que desea eliminar este registro?</v-card-title>
            <v-card-text>Realizada esta acción no podra revertirla y el registro quedara eliminado.</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :loading="btnGuardarLoading" outlined="" @click="openModal = false">
                    <v-icon left="">mdi-backspace</v-icon> Cancelar
                </v-btn>
                <v-btn color="success" :loading="btnGuardarLoading" @click="eliminar()">
                    <v-icon left="">mdi-check-bold</v-icon> Sí, acepto
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            openModal: false,
            idoptica: 0,
            btnGuardarLoading: false
        }
    },
    props: {
        objModalEliminar: {
            type:Object
        },
    },
    watch: {
        objModalEliminar(val) {
            this.openModal = val.openModal;
            this.idoptica = val.idoptica;
        },
    },

    methods: {
        eliminar() {
            this.btnGuardarLoading = true;

            this.axios({
                method: 'DELETE',
                url: 'api/panel/opticas/' + this.idoptica,

            }).then((response) => {

                this.openModal = false;

                this.$toasted.success(response.data.data, { icon: 'mdi-check-bold' });
                this.$parent.listarRegistros();

            }).catch((error) => {
                this.$toasted.error("Ocurrio un error al eliminar este registro", { icon: 'mdi-close' });
            }).finally(() => {
                this.btnGuardarLoading = false;
            });
        }
    },

}
</script>
